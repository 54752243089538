import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportMonthlyModel } from 'src/app/models/report-monthly-model';
import { AnalyzeReportOperatorModelPaginated } from 'src/app/models/analyze-report-operator-model-paginated';
import { MinioService } from 'src/app/services/minio.service';
import { ReportEngineService } from 'src/app/services/report-engine.service';
import { MatDialog } from '@angular/material/dialog';
import { ParameterModalComponent } from './parameter-modal/parameter-modal.component';

import Swal from "sweetalert2";
import { USER_DATA } from 'src/app/utils/constants.util';
import { CoreService } from 'src/app/services/migrate/api.core.service';

@Component({
  selector: 'app-reporting-mechanism',
  templateUrl: './reporting-mechanism.component.html',
  styleUrls: ['./reporting-mechanism.component.scss']
})
export class ReportingMechanismComponent implements OnInit {
  public reportEngineService: ReportEngineService;
  public minioService: MinioService;
  public serviceCore: CoreService;

  public datePipe: DatePipe;
  public actualPage: PageEvent;

  public analyzeReportOperatorModelsPaginated: AnalyzeReportOperatorModelPaginated;
  public analyzeReportOperatorModel: ReportMonthlyModel;
  public analyzeReportOperatorsPage: AnalyzeReportOperatorModelPaginated;

  public analyzesOperators: MatTableDataSource<any> =
    new MatTableDataSource<any>();

  public tableColumns: string[];
  public typeFieldSelect = [
    { name: "Tipo de Relatorio", value: "NAME" },
    { name: "Usuario", value: "USER" },
  ];

  public companys = new Array<any>();
  public permissions: [];
  private intervalId: any;

  public filter = "";
  public field = "";
  public typeFieldFilterSelected;
  public errorMessage;

  constructor(
    private dialog: MatDialog,
    datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    reportEngineService: ReportEngineService,
    minioService: MinioService,
    apiserviceCore: CoreService
  ) {
    this.datePipe = datePipe;
    this.reportEngineService = reportEngineService;
    this.minioService = minioService;
    this.serviceCore = apiserviceCore;
  }

  ngOnInit(): void {
    this.typeFieldFilterSelected = this.typeFieldSelect[0].value;
    this.field = this.typeFieldFilterSelected;
    this.initialPage();
    this.loadReports(this.actualPage, this.filter, this.field);
    this.loadTableDefinitions();
    this.startCallingLoadReports();
  }

  ngOnDestroy(): void {
    this.stopCallingLoadReports();
  }

  private startCallingLoadReports(): void {
    console.log("Data atual:", new Date());
    this.intervalId = setInterval(() => {
      this.loadReports(this.actualPage, this.filter, this.field);
    }, 50000); // Chama o método loadReports a cada 50 segundos
  }

  private stopCallingLoadReports(): void {
    clearInterval(this.intervalId); // Para de chamar loadReports
  }

  private async loadReports(page: PageEvent, name: string, field: string): Promise<void> {
    this.spinner.show();

    const companys = await this.serviceCore.get<any>({
      url: "v2/companys",
      params: {
        profileId: USER_DATA().profileId,
        size: "5000",
        page: "0",
        sort: "nome",
        sortDirections: "ASC",
      },
    });

    this.companys = companys?.elements;

    this.reportEngineService
      .getAllReports(page, name, field)
      .subscribe(
        (t) => {
          this.analyzeReportOperatorsPage = t;
          this.analyzesOperators =
            new MatTableDataSource<any>(
              this.analyzeReportOperatorsPage.content
            );
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter as analises do operador");
          this.spinner.hide();
        }
      );
  }

  fieldFilter(): void {
    this.field = this.typeFieldFilterSelected;
    this.filter = "";
  }

  applyFilter(): void {
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private loadTableDefinitions(): void {
    this.tableColumns = [
      "dateStart",
      "dateEnd",
      "statusReport",
      "NameReportType",
      "multipleFiles",
      "nmUser",
      "parameters",
      "pdfMinio",
      "xlsxMinio",
      "jsonMinio",
      "viewMultiples",
      "delete",
    ];
  }

  openParameterModal(parameters: any): void {
    if (!parameters) {
      console.warn("Nenhum parâmetro disponível para exibir.");
      return;
    }

    // Converte a string JSON em um objeto, se necessário
    let paramObject;
    try {
      paramObject = typeof parameters === 'string' ? JSON.parse(parameters) : parameters;
    } catch (error) {
      console.error("Erro ao analisar parâmetros JSON:", error);
      return;
    }

    // Formatar os parâmetros, excluindo valores null/"null"/"NULL" e convertendo true/false para SIM/NÃO
    const formattedParameters = Object.entries(paramObject)
      .filter(([_, value]) => value !== null && value !== "null" && value !== "NULL")
      .map(([key, value]) => {
        let displayValue;

        // Substituir true/false por SIM/NÃO
        if (value === true || value === "true") {
          displayValue = "SIM";
        } else if (value === false || value === "false") {
          displayValue = "NÃO";
        } else {
          displayValue = value;
        }

        // Formata DATAINICIO e DATAFIM para o formato brasileiro se não forem nulos
        if ((key === "DATAINICIO" || key === "DATAFIM") && typeof displayValue === 'string') {
          displayValue = this.formatDateToBR(displayValue);
        }

        // Substitui MODELORELATORIO por valores legíveis
        if (key === "MODELORELATORIO") {
          if (displayValue === "MONTHLY_VEHICLE") {
            displayValue = "POR VEICULOS";
          } else if (displayValue === "MONTHLY_DRIVER") {
            displayValue = "POR MOTORISTAS";
          }
        }

        // Busca o nome da empresa para IDEMPRESA e IDDIVISAO com condições específicas
        if (key === "IDEMPRESA" && displayValue !== "NULL") {
          // Se for IDEMPRESA, busca apenas onde cnpj não é nulo
          const company = this.companys.find(company => company.id === parseInt(String(displayValue), 10) && company.cnpj);
          if (company) {
            displayValue = company.nmNome;
          }
        } else if (key === "IDDIVISAO" && displayValue !== "NULL") {
          // Se for IDDIVISAO, busca apenas onde cnpj é nulo
          const division = this.companys.find(company => company.id === parseInt(String(displayValue), 10) && !company.cnpj);
          if (division) {
            displayValue = division.nmNome;
          }
        }

        return `${key} : ${displayValue}`;
      });

    this.dialog.open(ParameterModalComponent, {
      data: { formattedParameters },
      width: '35%',
      height: '35%'
    });
  }


  formatDateToBR(dateString: string): string {

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}-${minutes}-${seconds}`;
  }

  public async handleDelete(element): Promise<void> {
    Swal.fire({
      icon: "warning",
      title: "Excluir Relatório",
      html: `<h6>Tem certeza que você deseja excluir esse relatório? </h6>`,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      focusConfirm: false,
    }).then((action) => {
      if (action.isConfirmed) {
        this.deleteReports(element);
      }
    });
  }

  openToMultiples(uuid: string) {
    const url = "/relatorios/multiples-files/" + uuid;
    window.open(url, "_blank");
  }

  deleteReports(element): void {
    this.spinner.show();
    this.reportEngineService.deleteReport(element.uuid).subscribe(
      (v) => {
        console.log("Exclusão realizada com sucesso");
        this.loadReports(this.actualPage, this.filter, this.field);
        this.spinner.hide();
      },
      (e) => {
        console.log("Erro ao excluir o transportador");
        this.spinner.hide();
        this.errorMessage = e.message;
        throw new EvalError(e);
      }
    );
  }

  exportReport(element): void {
    // Use uma expressão regular para substituir os textos indesejados
    const cleanedElement = element.replace(/-BASE|-CONSUMO|-GRAFICO|-RESUMO/g, '');
    this.minioService.exportReport(cleanedElement).subscribe();
  }

  onChangePage(event: PageEvent): void {
    this.actualPage = event;
    this.loadReports(this.actualPage, this.filter, this.field);
  }

  private initialPage(): void {
    this.actualPage = new PageEvent();
    this.actualPage.pageIndex = 0;
    this.actualPage.pageSize = 20;
  }

  getStatusStyle(status: string): { [key: string]: string } {
    switch (status) {
      case "ERROR":
        return { color: "red" };
      case "FINISHED":
        return { color: "green" };
      case "PROCESSING":
        return { color: "blue" };
      default:
        return {}; // Estilo padrão, se o status não corresponder a nenhum caso
    }
  }


}
