<div class="main-page cadastros journey-main">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 my-2">
                <h1>Relatório de Posições</h1>
            </div>
        </div>
        <div style="margin-bottom: 45px;">
            <p-menubar #menu [model]="items"></p-menubar>
        </div>

        <mat-horizontal-stepper [linear]="true" #stepper>
            <mat-step>
                <!-- COMECA -->
                <p-table #journeyheader [value]="this.resultado" tableStyleClass="table">
                    <ng-template pTemplate="header" let-columns>
                        <tr [hidden]="this.resultado.length == 0">
                            <th style="text-align:center !important;border:none">Data Inicio</th>
                            <th style="text-align:center !important;border:none">Data Fim</th>
                            <th style="text-align:center !important;width: 80px;border:none">Tempo Ignição Desligada
                            </th>
                            <th style="text-align:center !important;width: 40px;border:none">Horímetro</th>
                            <th style="text-align:center !important;border:none">Latitude</th>
                            <th style="text-align:center !important;border:none">Longitude</th>
                            <th style="text-align:center !important;width: 180px; border:none">Endereço</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowdata>
                        <tr [hidden]="this.resultado.length == 0">
                            <td style="text-align:center !important;padding: 10px !important; border:none"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataInicio"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataFim"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["tempoIgnicaoDesligada"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["horimetro"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["latitude"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["longitude"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["endereco"]}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- FIM -->
                <!-- COMECA -->
                <p-table #journeyheader [value]="this.resultadoTotal" tableStyleClass="table-total">
                    <ng-template pTemplate="header" let-columns>
                        <tr [hidden]="this.resultadoTotal.length == 0">
                            <th style="text-align:center !important;border:none">Frota</th>
                            <th style="text-align:center !important;border:none">Placa</th>
                            <th style="text-align:center !important;border:none">Motorista</th>
                            <th style="text-align:center !important;border:none">Tempo Ignição Desligada Total</th>
                            <th style="text-align:center !important;border:none">KM Percorrido</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowdata>
                        <tr [hidden]="this.resultadoTotal.length == 0">
                            <td style="text-align:center !important;padding: 10px !important; border:none"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["frota"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["motorista"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["tempoTotalIgnicaoDesligada"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important;border:none" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["kmPercorrido"]}} KM</td>
                        </tr>
                    </ng-template>
                </p-table>
                <!-- FIM-->
                <!-- COMECA -->
                <p-table #journeyheader [value]="this.resultadoAnalitico" tableStyleClass="tableAnalitico">
                    <ng-template pTemplate="header" let-columns>
                        <tr [hidden]="this.resultadoAnalitico.length == 0" id="tabela-analitico"
                            [ngClass]="this.filterDialog == true ? '' : 'sticky-top'" style='top:65px;'>
                            <th style="text-align:center !important;border:none;width: 10%;" class="linha-analitico">
                                Data</th>
                            <th style="text-align:center !important;border:none;width: 7%;" class="linha-analitico">
                                Placa</th>
                            <th style="text-align:center !important;border:none;width: 5%;" class="linha-analitico">
                                Frota</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Motorista</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Status Ignição
                            </th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Odômetro</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">RPM</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Velocidade</th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">Tipo Velo.
                            </th>
                            <th style="text-align:center !important;border:none" class="linha-analitico">KM Percorrido
                            </th>
                            <th style="text-align:center !important;border:none; width: 135px;" class="linha-analitico">
                                Latitude</th>
                            <th style="text-align:center !important;border:none; width: 135px;" class="linha-analitico">
                                Longitude</th>
                            <th style="text-align:center !important; border:none ;width: 20%;" class="linha-analitico">
                                Endereço</th>
                        </tr>
                    </ng-template>

                    <br>
                    <ng-template pTemplate="body" let-rowdata>
                        <tr>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["dataAtualizacao"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["frota"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["motoristaNome"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'IGNIÇÃO DESLIGADA'"
                                style="text-align:center !important; color: #f51515;     border-bottom: 1px solid;"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'IGNIÇÃO LIGADA'"
                                style="text-align:center !important;color: #05d338;    border-bottom: 1px solid;"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == null" style="text-align:center !important"
                                align="center" valign=middle>-</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'NÃO CONTÉM'" style="text-align:center !important;"
                                align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["odometro"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["rpm"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["velocidade"]}} KM</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata"> {{rowdata["tipoVelocidade"]}} </td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["kmPercorrido"]}} KM</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["latitude"]}}</td>
                            <td style="text-align:center !important;    border-bottom: 1px solid;" align="center"
                                valign=middle [pRowToggler]="rowdata">{{rowdata["longitude"]}}</td>

                            <td *ngIf="!consultaFormGroup.get('searchAddress')?.value"
                                style="text-align:center !important;border-bottom: 1px solid" align="center"
                                valign="middle" [pRowToggler]="rowdata">
                                <span class="alerta mr-2" data-title="Visualizar" matTooltip="Visualizar">
                                    <a class="btn btn-link">
                                        <img (click)="openMap(rowdata['latitude'], rowdata['longitude'])" height="15"
                                            src="assets/img/view.svg" alt="" />
                                    </a>
                                </span>
                            </td>

                            <td *ngIf="consultaFormGroup.get('searchAddress')?.value"
                                style="text-align:center !important;border-bottom: 1px solid" align="center"
                                valign="middle" [pRowToggler]="rowdata">
                                {{ rowdata["endereco"] }}
                            </td>

                        </tr>
                    </ng-template>

                    <!-- <ng-template pTemplate="header" let-columns>            
                        <tr [hidden]="this.resultadoAnalitico.length == 0">
                            <th style="text-align:center !important;border:none">Data</th>
                            <th style="text-align:center !important;width: 40px;border:none">Placa</th>
                            <th style="text-align:center !important;border:none">Motorista</th>
                            <th style="text-align:center !important;border:none">Status Ignição</th>  
                            <th style="text-align:center !important;width: 40px;border:none">Velocidade</th>     
                            <th style="text-align:center !important;width: 40px;border:none">Hodômetro</th>        
                            <th style="text-align:center !important;border:none">KM Percorrido</th>            
                            <th style="text-align:center !important;border:none; width: 175px;" >endereco</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowdata>          
                        <tr>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["dataAtualizacao"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["placa"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["motoristaNome"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'IGNIÇÃO DESLIGADA'" style="text-align:center !important;padding: 10px !important;  color: #f51515; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td *ngIf="rowdata['statusIgnicao'] == 'IGNIÇÃO LIGADA'" style="text-align:center !important;padding: 10px !important;  color: #05d338;border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["statusIgnicao"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["velocidade"]}} KM</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["hodometro"]}}</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["kmPercorrido"]}} KM</td>
                            <td style="text-align:center !important;padding: 10px !important; border:none" align="center" valign=middle [pRowToggler]="rowdata">{{rowdata["endereco"]}}</td>     
                        </tr>                 
                    </ng-template> -->
                </p-table>
                <!-- FIM-->
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>

<p-sidebar [(visible)]="this.filterDialog" position="right">
    <div style="margin-bottom: 20px;">
        <span>Filtros</span>
    </div>
    <form [formGroup]="this.consultaFormGroup">
        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Data Inicío</mat-label>
                    <input formControlName="dataInicio" type="datetime-local" matInput placeholder="Inicio" required />
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-lg-12">
                <mat-form-field appearance="outline">
                    <mat-label>Data Fim</mat-label>
                    <input formControlName="dataFim" type="datetime-local" matInput placeholder="Fim" required />
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de Empresa</mat-label>
                    <mat-select formControlName="selecaoEmpresa" [(value)]="empresaFilterSelected"
                        [(ngModel)]="empresaFilterSelected" (selectionChange)="this.filterCompanyReports()">
                        <mat-option *ngFor="let type of companiesSelect" [value]="type.name">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Empresa</mat-label>
                    <input type="text" matInput [formControl]="controlEmpresa" [matAutocomplete]="autoEmpresa" required>
                    <mat-autocomplete #autoEmpresa="matAutocomplete" [displayWith]="displayEmpresa">
                        <mat-option *ngFor="let type of this.companiesFilter" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline" *ngIf="controlEmpresa.value != ''">
                    <mat-label>Placa / Frota</mat-label>
                    <input type="text" matInput [formControl]="controlVeiculo" [matAutocomplete]="autoTerminal"
                        required>
                    <mat-autocomplete #autoTerminal="matAutocomplete" [displayWith]="displayTerminal">
                        <mat-option *ngFor="let type of this.vehiclesFiltered | async" [value]="type">
                            {{type.cdPlaca}}/{{type.frota}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Motorista</mat-label>
                    <input type="text" matInput [formControl]="controlMotorista" [matAutocomplete]="autoMotorista">
                    <mat-autocomplete #autoMotorista="matAutocomplete" [displayWith]="displayMotorista">
                        <mat-option *ngFor="let type of this.driversFiltered| async" [value]="type">
                            {{type.nmNome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="form-row"
            *ngIf="this.permissionService.hasPermission('ENDERECO_INTERNO_FUNCIONALIDADE', this.permissions)">
            <div class="form-group col-md-12">
                <mat-slide-toggle name="searchAddress" formControlName="searchAddress" color="primary">
                    Buscar Endereço Internamente?</mat-slide-toggle>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo:</mat-label>
                    <select matNativeControl formControlName="tipoRelatorio">
                        <option value=1 selected>
                            Sintético
                        </option>
                        <option value=2>
                            Analítico
                        </option>
                    </select>
                </mat-form-field>
            </div>
        </div>


        <div class="form-row">
            <div class="form-group col-md-8">
                <!-- [disabled]="!this.lastAnswers[this.lastAnswers.length -1].msg && !this.finalizar" class="ml-1" mat-flat-button color="primary" (click)="onSave()" -->
                <button type="submit" (click)="onConsultar()">
                    Consultar
                </button>
            </div>
        </div>


    </form>

</p-sidebar>