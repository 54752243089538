import { CurrentPosition } from '../../models/CurrentPosition';
import { MapPin } from '../../models/MapPin.model';
import { Injectable } from '@angular/core';
import { MapToolboxService } from '../../services/map.toolbox.service';
import { BehaviorSubject } from 'rxjs';
import { TireSensor } from "../../models/TireSensor.model";
import { TerminalInput } from "../../models/terminalInput.model";
import { TerminalOutput } from "../../models/TerminalOutput.model";

@Injectable({
    providedIn: 'root',
})
export class TerminalMarker extends CurrentPosition {
    private marker$ = new BehaviorSubject<any>(null);
    pin: MapPin;
    textPlate: string;
    position: {
        latitude: number;
        longitude: number;
    };
    isShowMarkerLabel: boolean;
    showInfoWindow: boolean;
    // Define se o marker é de uma posição atual ou de histórico
    historic: boolean;
    // Define se os dados atualizados do termibnal foram carregados
    load: boolean;
    // Controla a exibição do popup de telemetria
    popupTelemetria: boolean;
    markerTelemetria: any;

    /**
     * Compatibilidade com a atual VW_POSICAO_ATUAL_TERMINAL
     * TODO: REVER
     *
     */
    nrEnumTipoVelocidade: number;
    velocidade: number;
    velocidadeObd: number;
    odometro: number;
    odometroVeiculo: number;
    combustivelConsumido: number;
    combustivelConsumidoParado: number;
    combustivelConsumidoAcumulador: number;
    combustivelRemanescente: number;
    combustivelTanque: number;
    consumoInstantaneo: number;
    nivelBateria: number;
    voltagemBateriaVeiculo: number;
    tempoHorimetroMotorTexto: string;
    horimetroMotor: number;
    atividade: string;
    categoriaVeiculo: string;
    codigoAtividade: number;
    dsObservacao: string;
    terminal_imei: string;
    terminal_simCard: string;
    terminalAlarme1: any;
    terminalAlarme2: any;
    terminalAlarme3: any;
    terminalAlarme4: any;
    terminalAlarme5: any;
    telemetry: any;
    altitude: number;
    versaoFirmware: string;
    enumStatusGps: number;
    mccMncCelula: string;
    mncCelula: string;
    lacCelulaGsm: string;
    idCelulaGsm: string;
    eventos: any;
    pneuPosition: string;
    // Campos incluidos para tratar do histórico. Rever com mais calma a necessidade
    aceleracoesFundo: number;
    dataAtualizacao: Date;
    enumIndicadorVeiculo: string;
    enumStatusIgnicao: number;
    horimetroMovimento: number;
    horimetroParado: number;
    nivelCombustivel: number;
    observacoes: string;
    pressaoTurbo: number;
    rpmMotor: number;
    temperaturaMotor: number;
    tempoExcessoPressaoTurbo: number;
    tempoExcessoVelocidade: number;
    tempoRpmAmarelo: number;
    tempoRpmVerde: number;
    tempoRpmVermelho: number;
    totalFrenagens: number;
    aceleracaoBrusca: number;
    ativo: boolean;
    embreagemAcionada: number;
    freioAcionado: number;
    frenagemBrusca: number;
    frenagemMuitoBrusca: number;
    latitudeLongitudeGsm: string;
    sensoresPneu: Array<TireSensor>;
    entradasTerminal: Array<TerminalInput>;
    saidasTerminal: Array<TerminalOutput>;
    numeroChassi: string;
    numeroSatelites: number;
    odometroAcumulador: number;
    online: boolean;
    pilotoAutomatico: boolean;
    posicaoAcelerador: number;
    pressaoCombustivel: number;
    qualidadeSinalGsm: number;
    senhaMotorista: string;
    taxaEntradaArMotor: number;
    tempDig1: number;
    tempDig2: number;
    temperaturaArAdmissao: number;
    tempoBanguela: number;
    tempoDescanco: number;
    tempoDirecao: number;
    tempoExcessoRpm: number;
    tempoJornada: number;
    tempoParada: number;
    tempoPontoMorto: number;
    textoObservacaoBalao: string;
    mediaHistorica: number;
    tipoRedeGsm: number;
    torqueMotor: number;
    trocaCarroceria: any; // TODO Verificar o tipo de informação que vem aqui
    voltagemBateriaBackup: number;


    constructor(private mapService: MapToolboxService) {
        super();
    }

    build(position: CurrentPosition): void {
        this.id = position.id;
        this.id_terminal = position.id_terminal;
        this.id_empresa = position.id_empresa;
        this.nome_empresa = position.nome_empresa;
        this.id_divisao = position.id_divisao;
        this.nome_divisao = position.nome_divisao;
        this.id_motorista = position.id_motorista;
        this.nome_motorista = position.nome_motorista;
        this.direcao = position.direcao;
        this.latitude = position.latitude;
        this.longitude = position.longitude;
        this.tag = position.tag;
        this.placa = position.placa;
        this.frota = position.frota;
        this.data_online = position.data_online;
        this.pin = new MapPin(this.direcao, this.tag, null, null, false);
        this.textPlate = this.placa;
        this.position = { latitude: this.latitude, longitude: this.longitude };
    }

    buildSensoresPneu(r: any) {
        // TODO implementar geração do array de sensores de pneu;
        return null;
    }

    buildEntradasTerminal(r: any) {
        // TODO implementar geração de arra de entradas
        return null;
    }

    buildSaidasTerminal(r: any) {
        // TODO implementar geração de arra de entradas
        return null;
    }

    public openTelemetria(marker): void {
        this.markerTelemetria = marker;
        this.popupTelemetria = true;
    }

    onCenterPosition(item): void {

        const data = {
            action: 'zoom_in_path',
            values: {
                bounds: [
                    {
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude),
                    },
                ],
            },
        };
        this.mapService.setCommand(data);
    }

    public sendMarker(marker) {
        this.marker$.next(marker);
    }
}
