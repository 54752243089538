<div class="main-page consultas">
  <div class="container-fluid">
    <!-- LINHA TITULO -->
    <div class="row align-items-center">
      <div class="col-12 my-2">
        <h1>Sobre o que você deseja consultar?</h1>
      </div>
    </div>
    <!-- ITEM CARDS -->
    <div class="row mt-3">
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('COMPANY_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/consulta/COMPANY_FUNCIONALIDADE'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/empresas.svg" height="40" alt="" />
              <h5>Empresa</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="this.permissionService.hasPermission('USER_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/consulta/USER_FUNCIONALIDADE'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/usuario.svg" height="40" alt="" />
              <h5>Usuário</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('VEHICLE_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/consulta/VEHICLE_FUNCIONALIDADE'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/veiculo.svg" height="40" alt="" />
              <h5>Veículo</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('SENSOR_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/sensor'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/sensor.svg" height="40" alt="" />
              <h5>Sensor TPMS</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="this.permissionService.hasPermission('PNEU_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/pneu'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/pneu.svg" height="40" alt="" />
              <h5>Gestão de Pneu</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('CAMBUS_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/cambus-dados'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/cambus.svg" height="55" alt="" />
              <h5>Dados CANBUS</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TERMINAL_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/terminal'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/processador.svg" height="40" alt="" />
              <h5>Terminal</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('DRIVER_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/drivers'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/motorista.svg" height="40" alt="" />
              <h5>Motorista</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('MAINTENANCE_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/maintenance'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/manutencao.svg" height="40" alt="" />
              <h5>Manutenção</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TRANSPORTER_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/transporters'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/motorista.svg" height="40" alt="" />
              <h5>Transportadores/Agregados</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('CERCA_ELETRONICA_FUNCIONALIDADE', this.permissions)">
        <!--/cadastros-cerca.html-->
        <a (click)="this.router.navigate(['/eletronic-fences'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/cerca.svg" height="40" alt="" />
              <h5>Cerca Eletrônica</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="this.permissionService.hasPermission('ROUTE_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/routes'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/rota.svg" height="40" alt="" />
              <h5>Rota</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TERMINAL_ALARME_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/terminal-alarme'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/controle.svg" height="40" alt="" />
              <h5>Eventos</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('ATIVIDADE_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/atividades'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/agenda.svg" height="40" alt="" />
              <h5>Atividade</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('PERFIL_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/perfil'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/escudo.svg" height="40" alt="" />
              <h5>Perfil</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('PERFIL_TIPO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/perfil-tipo'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/compartilhar.svg" height="40" alt="" />
              <h5>Tipo de Perfil</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('MODULO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/modulo'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/modulo.svg" height="40" alt="" />
              <h5>Módulo</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('FUNCIONALIDADE_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/funcionalidade'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/funcao.svg" height="40" alt="" />
              <h5>Funcionalidade</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('FUNCIONALIDADE_TIPO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/funcionalidade-tipo'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/funcao2.svg" height="40" alt="" />
              <h5>Tipo de Funcionalidade</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('SUPERVISOR_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/supervisor'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/supervisor.svg" height="85" alt="" />
              <h5>Supervisor</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('GESTOR_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/gestor'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/manager.svg" height="85" alt="" />
              <h5>Gestor</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="this.permissionService.hasPermission('OCULTO', this.permissions)">
        <a (click)="this.router.navigate(['/relatorio-operacional-assincrono/detail'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/report-async.svg" height="55" alt="" />
              <h5>Relatório Operacional Assincrono</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('ANALISE_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/analise-operacional'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/report-async.svg" height="55" alt="" />
              <h5>Análise Operacional</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="gestor != null">
        <a (click)="this.router.navigate(['/analise-gestor'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/analyze-manager.svg" height="55" alt="" />
              <h5>Análise Gestor</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2" *ngIf="supervisor != null">
        <a (click)="this.router.navigate(['/analise-supervisor'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/analyze-supervisor.svg" height="55" alt="" />
              <h5>Análise Supervisor</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('ANALYZE_HISTORIC_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/analise-historico'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/analyze-historic.svg" height="55" alt="" />
              <h5>Historico de Análises</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TIPO_PARAMETRO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/tipo-parametro'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/tipo-parametro.svg" height="55" alt="" />
              <h5>Tipo de Paramêtros Relatorios</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('REGRA_RELATORIO_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/regra-relatorio'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/relatorio-regra.svg" height="55" alt="" />
              <h5>Regras Relatorio</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('RELATORIOS_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/relatorios'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/report.svg" height="55" alt="" />
              <h5>Consulta de Relatórios</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
      <div class="col-lg-4 my-2"
        *ngIf="this.permissionService.hasPermission('TRACKER_FILES_FUNCIONALIDADE', this.permissions)">
        <a (click)="this.router.navigate(['/tracker-files'])">
          <div class="card justify-content-center text-center">
            <div>
              <img src="assets/img/icons-page/tracker-files.svg" height="55" alt="" />
              <h5>Vídeos & Fotos</h5>
            </div>
          </div>
        </a>
      </div>
      <!-- ITEM -->
    </div>
  </div>
</div>